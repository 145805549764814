import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, input, Input, Output} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {Router, RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MsaEmptyListBoxComponent} from '@shared/shared-module/components/msa-empty-list-box/msa-empty-list-box.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {GERMAN_DATE_TIME} from '@shared/shared-module/utils/date-time.utils';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {MsaButtonComponent} from '../../../../../src/app/shared-module/components/msa-button/msa-button.component';

@Component({
  selector: 'msa-notification-card',
  templateUrl: './notification-card.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    SafeTranslatePipe,
    SafeTranslateDirective,
    MsaEmptyListBoxComponent,
    MatCardModule,
    MsaButtonComponent
  ]
})
export class NotificationCardComponent {
  @Input() creationDateTime: string = '';
  @Input() subject: string = '';
  @Input() body: string = '';
  @Input() buttonText: TranslationString = '';
  @Input() buttonLink: string = '';
  @Input() referenceId: string = '';
  public unread = input(false);
  @Input() id: string = '';
  @Output() markAsRead = new EventEmitter<string>();
  private router = inject(Router);

  openNotification() {
    this.markAsRead.emit(this.id);
    this.router.navigate([this.buttonLink]);
  }

  protected readonly GERMAN_DATE_TIME = GERMAN_DATE_TIME;
}
