import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {Store} from '@ngxs/store';
import {PERSON_DATA_PATH} from '@shared/core/constants/url.const';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {FetchAllNotifications, MarkAsRead} from '@shared/shared-module/stores/actions/notifications.state.actions';
import {NotificationsStateSelectors} from '@shared/shared-module/stores/selectors/notifications.state.selectors';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {MsaButtonComponent} from '../../../../src/app/shared-module/components/msa-button/msa-button.component';
import {NotificationCardComponent} from './notification-card/notification-card.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'msa-notifications',
  templateUrl: './notifications.component.html',
  standalone: true,
  imports: [CommonModule, MatIconModule, NotificationCardComponent, SafeTranslatePipe, RouterLink, MsaButtonComponent]
})
export class NotificationsComponent implements OnInit {
  private store = inject(Store);

  public groupedNotifications = readStoreSignal(NotificationsStateSelectors.groupedNotifications);

  ngOnInit() {
    this.store.dispatch(new FetchAllNotifications());
  }

  markAsRead(notificationId: string) {
    this.store.dispatch(new MarkAsRead(notificationId));
  }

  protected readonly PERSON_DATA_PATH = PERSON_DATA_PATH;
}
