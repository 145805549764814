import {NgModule} from '@angular/core';
import {environment} from '../../../../src/environments/environment';

import {RouterModule} from '@angular/router';
import {
  MsaNotificationConfiguration,
  MsaNotificationConfigurationParameters
} from './core/api/generated/msa-notification';
import {NotificationCardComponent} from './notification-card/notification-card.component';
import {NotificationsComponent} from './notifications.component';

export function msaNotificationConfigurationFactory(): MsaNotificationConfiguration {
  const params: MsaNotificationConfigurationParameters = {
    basePath: environment.api.notificationUrl
  };
  return new MsaNotificationConfiguration(params);
}

@NgModule({
  imports: [
    RouterModule.forChild([{path: '', component: NotificationsComponent}]),
    NotificationsComponent,
    NotificationCardComponent
  ],
  providers: []
})
export class NotificationsModule {}
