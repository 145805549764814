<div class="tw-bg-black-light-1 tw-p-30 tw-pt-24" data-cy="notificationCard">
  <div class="tw-mb-6 tw-flex tw-flex-row tw-items-center tw-gap-6">
    @if (unread()) {
      <div class="tw-size-[10px] tw-rounded-full tw-bg-tuscany"></div>
    }
    <p class="msa-text-small tw-text-black-light-5.5">{{ creationDateTime | date: GERMAN_DATE_TIME }}</p>
  </div>

  <div class="tw-flex tw-flex-col tw-items-center tw-justify-between d:tw-flex-row">
    <div>
      <p class="msa-text tw-font-bold">{{ subject }}</p>
      <p class="msa-text-small tw-pt-9 d:tw-pt-18">{{ body }}</p>
    </div>

    <msa-button
      class="tw-mt-12 tw-w-full d:tw-mt-0 d:tw-w-auto"
      (click)="openNotification()"
      data-cy="openNotification"
      color="secondary"
    >
      {{ buttonText | safeTranslate }}
    </msa-button>
  </div>
</div>
